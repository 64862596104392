angular.module('ngIntlTelInput', []);
angular.module('ngIntlTelInput')
    .provider('ngIntlTelInput', function () {
        var me = this;
        var props = {};
        var setFn = function (obj) {
            if (typeof obj === 'object') {
                for (var key in obj) {
                    props[key] = obj[key];
                }
            }
        };
        me.set = setFn;

        me.$get = ['$log', function ($log) {
            return Object.create(me, {
                init: {
                    value: function (elm) {
                        if (!window.intlTelInputUtils) {
                            $log.warn('intlTelInputUtils is not defined. Formatting and validation will not work.');
                        } else {
                            props.intlTelInput = '/public/js/plugin/intl-tel-input/build/js/utils.js';
                            me.intl = window.intlTelInput(elm,props);
                            console.log(elm);
                        }
                        //..elm.intlTelInput(props);
                    }
                },
            });
        }];
    });
angular.module('ngIntlTelInput')
    .directive('ngIntlTelInput', ['ngIntlTelInput', '$log',
        function (ngIntlTelInput, $log) {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, elm, attr, ctrl) {
                    // Warning for bad directive usage.
                    if ((!!attr.type && (attr.type !== 'text' && attr.type !== 'tel')) || elm[0].tagName !== 'INPUT') {
                        $log.warn('ng-intl-tel-input can only be applied to a *text* or *tel* input');
                        return;
                    }
                    // Override default country.
                    if (attr.defaultCountry) {
                        ngIntlTelInput.set({initialCountry: attr.defaultCountry});
                    }
                    // Initialize.
                    ngIntlTelInput.init(elm[0]);
                    // Validation.
                    ctrl.$validators.ngIntlTelInput = function (value) {
                        // if phone number is deleted / empty do not run phone number validation
                        if (value || elm[0].value.length > 0) {
                            return ngIntlTelInput.intl.isValidNumber();
                        } else {
                            return true;
                        }
                    };
                    // Set model value to valid, formatted version.
                    ctrl.$parsers.push(function (value) {
                        return ngIntlTelInput.intl.getNumber().replace(/[^\d]/, '');
                    });
                    // Set input value to model value and trigger evaluation.
                    ctrl.$formatters.push(function (value) {
                        if (value) {
                            if(value.charAt(0) !== '+') {
                                value = '+' + value;
                            }
                            ngIntlTelInput.intl.setNumber(value);
                        }
                        return value;
                    });
                }
            };
        }]);
